footer {
	padding-top: 2rem;
	padding: 3rem 0 3.5rem;
	z-index: 9;
	position: relative;
}

.dark__bg {
	background-color: var(--first-color-second);
}

.footer__container {
	row-gap: 3.5rem;
	display: flex;
	justify-content: space-between;
	margin: 0 4rem;
	color: #fff;
}

.footer__title {
	font-size: var(--h1-font-size);
	margin-bottom: var(--mb-0-25);
	color: var(--title-color);
	font-weight: var(--font-semi-bold);
}

.footer__subtitle {
	font-size: var(--small-font-size);
}

.footer__links {
	display: flex;
	flex-direction: row;
	column-gap: 2rem;

	a {
		transition: 0.2s;

		&:hover {
			color: var(--first-color-lighter);
		}
	}
}

.footer__socials {
	justify-self: flex-end;

	a {
		font-size: 1.25rem;
		margin-right: var(--mb-1-5);
		transition: 0.2s;

		&:hover {
			color: var(--first-color-lighter);
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.footer__copyright {
	font-size: var(--smaller-font-size);
	text-align: center;
	color: var(--text-color-light);
	margin-top: var(--mb-3);
}

@media screen and (max-width: 900px) {
	.footer__links {
		flex-direction: column;
		row-gap: 1rem;
	}
}

@media screen and (max-width: 630px) {
	.footer__socials {
		display: flex;
		flex-direction: column;
	}
}

@media screen and (max-width: 470px) {
	.footer__container {
		flex-direction: column;
		align-items: center;
		text-align: center;
		row-gap: 2rem;
	}
	.footer__socials {
		flex-direction: row;
	}
}
