.qualification__item {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 1rem 0;

	.qualification__year {
		margin-right: 2rem;
		font-weight: 800;
		color: var(--first-color);


		@media screen and (max-width: 450px) {
			margin-right: 1rem;
		}
	}

	.qualification__name {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 1rem;
		background-color: var(--body-color);
		border-radius: 0.5rem;
		z-index: 9;
		position: relative;

		.qualification__name-title {
			font-weight: 500;
			color: var(--title-color);
		}

		.qualification__name-description {
			font-size: var(--small-font-size);
			color: var(--text-color-light);
		}
	}
}
