.button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 180px;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
  transition: 0.2s;
  z-index: 9;

  &:hover {
    background-color: var(--first-color-alt);
  }

  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover svg {
    transform: rotate(90deg);
  }
}
