.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  box-shadow: 0 -1px 9px rgba(0, 0, 0, 0);
}

/* Active link */
.active-link {
  color: var(--first-color) !important;
}

// /* Change background header */
.header.scroll-header {
  box-shadow: 0 -1px 9px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 840px) {
    box-shadow: 0 -1px 9px rgba(0, 0, 0, 0);
  }
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo {
  transition: 0.2s ease;

  img {
    width: 25px;
    margin-right: 0.5rem;
  }

  &:hover {
    color: var(--first-color);
  }
}

.nav__logo-container {
  display: flex;
  align-items: center;
}

.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
  transition: 0.2s ease;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--first-color);
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  text-align: center;
  transition: 0.2s;

  &:hover {
    color: var(--first-color);
  }
}

.nav__icon {
  font-size: 1.2rem;
}

.nav__close {
  font-size: 1.25rem;
  cursor: pointer;
  color: var(--first-color);

  &:hover {
    color: var(--first-color-alt);
  }
}

/* show menu */
.show-menu {
  bottom: 0 !important;
}

.nav__btns {
  margin-left: 2rem;
  display: flex;
  z-index: 10;
}

/* ============= MEDIA QUERIES ============= */
@media screen and (min-width: 990px) {
  .nav__list {
    column-gap: 2rem !important;
  }
}

@media screen and (max-width: 840px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }

  .toggle-theme-wrapper {
    margin-left: 0;
    margin-right: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 841px) {
  .nav {
    height: calc(var(--header-height) + 1.5rem);
    max-width: 100%;
  }

  .nav__logo {
    font-size: 1.35rem;
  }

  .nav__logo img {
    width: 30px;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
    column-gap: 1rem;
  }

  .nav__menu {
    margin-left: auto;
  }
}

@media screen and (max-width: 365px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }
}
