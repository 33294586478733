/*========== Variables Dark theme ==========*/
[data-theme='dark'] {
  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  span {
    font-size: 21px;
    display: flex;
    align-items: center;
  }
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 23px;
  width: 48px;

  input {
    display: none;

    &:checked + .slider:before {
      transform: translateX(25px);
    }

    &:checked + .slider {
      background-color: var(--first-color-second);
    }
  }
}

.slider {
  background-color: var(--first-color-second);
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;

  &:before {
    background-color: #fff;
    bottom: 2px;
    content: '';
    height: 19px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 19px;
  }

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.active-theme {
  color: var(--first-color-alt);
}
